
export default {
  name: 'affiliator-sale',
  props: {
    route: {
      type: String,
      default: '',
    },
    isAffiliatorLoaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAffiliatorDefined: false,
      isAffiliatorHome: false,
      showAffiliator: true,
    };
  },
  computed: {
    defaultSearch() {
      return  `avia:${this.$store.getters['sulu/airport/defaultSearch']}`;
    },
  },
  mounted() {
    window.customElements.whenDefined('razlet-affiliator').then(() => {
      this.isAffiliatorDefined = true;
    });
  },
};
